@import 'app/styles/variables';
@import 'app/styles/carousel';

.#{$search-prefix}-carousel {
  display: flex;
  justify-content: center;

  $min-entry-height: 190px;

  $entry-hover-background: $andes-blue-500;

  &-loading {
    height: 280px;
    width: calc(100vw - 100px);
  }

  &-with-left-menu-loading {
    height: 280px;
    width: calc(100vw - 415px);
    max-width: 1032px;
  }

  .container-with-left-menu.container,
  .container-without-left-menu.container {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    height: 280px;
  }

  .container-with-left-menu {
    width: calc(100vw - 415px);
    max-width: 1032px;
  }

  .container-without-left-menu {
    width: calc(100vw - 100px);
    max-width: 1264px;
  }

  &-wrapper {
    overflow: hidden;
    height: 100%;

    .andes-carousel-snapped__title {
      h2 {
        display: inline-flex;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        margin-block: 0 0;
      }
    }
  }

  .entry-column {
    min-height: $min-entry-height;
  }

  .one-page-search-entry-available .andes-carousel-snapped__wrapper {
    transform: none !important;
  }

  .andes-carousel-snapped__control--next,
  .andes-carousel-snapped__control--previous {
    top: 95px;
  }
}
