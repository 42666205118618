@import 'app/styles/variables';
@import '~@andes/common/index';

.#{$menu-prefix}-left {
  &-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-left: 51px;
    padding-right: 17px;
    padding-top: 24px;
    width: 247px;

    nav {
      width: inherit;
    }
  }

  &-breadcrumb {
    align-items: center;
    color: $andes-text-color-disabled;
    display: contents;
    font-family: $font-family;
    line-height: 20px;
    font-size: 16px;
    list-style-type: none;

    li {
      align-items: center;
      display: inline-flex;
      font-family: $font-family;
      font-size: 16px;
      line-height: 20px;
      list-style-type: none;
      text-align: left;
      text-decoration: none;
    }

    a {
      align-items: center;
      color: $andes-text-color-disabled;
      text-decoration: none;
    }

    .andes-breadcrumb__chevron {
      padding-left: 7.1px;
      padding-right: 9.95px;
    }
  }

  &-category {
    align-items: center;
    display: inline-block;
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 24px;
    width: 100%;
  }

  &-categories-title {
    color: $andes-text-color-primary;
    display: inline-block;
    font-family: $font-family;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    padding-top: 24px;
    width: 100%;
  }

  &-ul {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;
    padding-top: 16px;
    text-align: left;
    width: 100%;
  }

  &-li {
    display: flex;
    font-family: $font-family;
    list-style-type: none;
    text-align: left;
  }

  &-li-a {
    color: $andes-text-color-primary;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    text-decoration: none;
  }
}
