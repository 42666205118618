@import 'app/styles/variables';
@import '~@andes/common/index';
@import 'app/styles/variables';

.#{$view-prefix}-main {
  &-page-content {
    min-height: calc(100vh - 355px);
  }

  &-title-container {
    width: 100%;
    height: 103px;
    background: $andes-blue-700;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  &-mobile-title-container {
    height: 126px;
  }

  &-desktop-without-menu-title-container {
    height: 161.14px;
  }

  &-desktop-with-menu-title-container {
    height: 103.97px;
  }

  &-title {
    align-items: center;
    color: $andes-white;
    display: flex;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 8px;
    place-content: center;
    text-align: center;
    white-space: pre-line;
    width: 100%;
  }

  &-mobile-title {
    font-size: 20px;
    line-height: 25px;
  }

  &-desktop-title {
    font-size: 28px;
    line-height: 35px;
  }

  &-subtitle {
    align-items: center;
    color: $andes-white;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    place-content: center;
    text-align: center;
    width: 100%;
  }

  &-mobile-subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  &-desktop-without-menu-subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  &-desktop-with-menu-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
