@import 'app/styles/variables';
@import '~@andes/common/index';

.#{$search-prefix}-entry {
  $description-color: rgb(71 154 209 / 15%);

  @media (width <= 480px) {
    &-images-container {
      height: 80.59px;
      width: 145px;
    }

    &-description {
      margin-top: 16px;
    }

    &-thumbnail {
      height: 74.63px;
      width: 74.63px;
      border-radius: 126px;
    }

    &-thumbnail-img {
      align-content: center;
      align-items: center;
      max-width: 68px;
      max-height: 68px;
    }

    &-keyword {
      min-height: 40px;
    }
  }

  @media (width >= 480px) {
    &-images-container {
      height: 73.89px;
      width: 190px;
    }

    &-description {
      margin-top: 20px;
    }

    &-thumbnail {
      height: 68.53px;
      width: 68.53px;
      border-radius: 116px;
    }

    &-thumbnail-img {
      align-content: center;
      align-items: center;
      max-width: 68px;
      max-height: 68px;
    }

    &-keyword {
      min-height: 20px;
    }
  }

  &-mobile-container {
    padding: 16px;
    height: calc(100% - 32px);
  }

  &-desktop-with-menu-container {
    padding: 16px;
    width: 190px;
  }

  &-desktop-without-menu-container {
    padding: 20px;
    width: 190px;
  }

  &-container:hover {
    white-space: break-spaces;
  }

  &-desktop-with-menu-container:hover,
  &-desktop-without-menu-container:hover {
    box-shadow: 0 6px 16px $andes-gray-100;
  }

  &-images-wrapper {
    display: flex;
    justify-content: center;
  }

  &-images-container {
    position: relative;
  }

  &-container {
    display: block;
    height: 100%;
    text-decoration: none;
    white-space: nowrap;

    .ui-search-entry-thumbnail-img {
      height: auto;
      width: auto;
      object-fit: initial;
    }
  }

  &-keyword {
    font-family: $font-family;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    color: $andes-gray-900-solid;
    word-break: break-word;
    margin: 12px 0 0;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-description {
    display: flex;
    font-family: $font-family;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 0 4px;
    color: $andes-blue-500;
    font-size: 12px;
    min-height: 20px;
    background: $description-color;
    border-radius: 2px;
    line-height: 15px;
  }

  &-thumbnail {
    position: absolute;
    background-color: $andes-gray-040-solid;
    border: 3.48434px solid $andes-white;
    border-radius: 116.145px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &-thumbnail-desktop-image0 {
    left: 0;
  }

  &-thumbnail-desktop-image1 {
    left: 57.25px;
  }

  &-thumbnail-desktop-image2 {
    right: 0;
  }

  &-thumbnail-mobile-image0 {
    left: 0;
  }

  &-thumbnail-mobile-image1 {
    right: 0;
  }

  &-thumbnail-img {
    background: $andes-white;
    mix-blend-mode: multiply;
  }
}
