@import 'app/styles/variables';

.#{$view-prefix}-mobile {
  &-container {
    padding-bottom: 48px;
  }

  &-search-container-expandable {
    padding-top: 48px;
  }

  &-top-menu + &-search-container-expandable {
    padding-top: 24px;
  }

  &-category-container,
  &-search-container {
    padding-top: 68px;
  }

  &-search-container-consolidated {
    padding-top: 48px;
  }
}
