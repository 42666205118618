@import 'app/styles/variables';

.#{$category-prefix}-navigationContainerExpandable {
  $box-shadow-color: rgb(0 0 0 / 12%);

  .section-header {
    margin-left: 16.8px;

    h2 {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .container {
    margin-left: 11.6px;
    margin-right: 11.6px;
  }

  .wrapper {
    overflow: hidden;
    margin: 20px 0;

    .category-wrapper:nth-child(odd) {
      margin: 4px 4px 4px 0;
    }

    .category-wrapper:nth-child(even) {
      margin: 4px 0 4px 4px;
    }

    .category-wrapper:first-child {
      margin: 0 4px 4px 0;
    }

    .category-wrapper:nth-child(2) {
      margin: 0 0 4px 4px;
    }
  }

  .section-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    background-color: $andes-white;
    border-radius: 4px;
    box-shadow: 0 1px 2px $box-shadow-color;
    color: $andes-blue-500;
    font-family: $font-family;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-decoration: none;
    border: none;
    height: 54px;

    svg {
      margin-left: 5px;
    }
  }

  .category {
    border-radius: 4px;
    height: 171.27px;

    &-wrapper {
      box-sizing: border-box;
      float: left;
      width: calc(50% - 4px);
    }
  }

  .not-display {
    display: none;
  }
}
