@import './variables';

ul.andes-carousel-snapped__pagination.andes-carousel-snapped__pagination--position-top {
  align-self: center;
}

.andes-carousel-snapped__title {
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.andes-carousel-snapped__controls-wrapper {
  margin-top: 24px;
}

