@import 'app/styles/variables';
@import '~@andes/common/index';
@import 'app/styles/carousel';

$filler-color: #eae6e6;
$category-size: 170px;

.#{$category-prefix}-navigation-carousel {
  .container {
    display: flex;
    flex-flow: column wrap;
    flex-basis: 100%;
    align-content: center;
    width: calc(100vw - 100px);
    position: relative;
  }

  .wrapper {
    width: 100%;
    max-width: 1264px;

    .andes-carousel-snapped__title {
      h2 {
        display: inline-flex;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        margin-block: 0 0;
      }
    }
  }

  .icon img {
    max-height: 44px;
    width: auto;
  }

  .category,
  .filler:first-child {
    border-bottom: 1px solid $filler-color;
    border-right: 1px solid $filler-color;
  }

  .category,
  .filler {
    height: $category-size - 10px;
  }

  .category {
    border-radius: 4px;

    &-column {
      height: 2 * $category-size;
      width: 100%;
      float: left;
    }

    & + .category {
      bottom: 0;

      &:hover {
        bottom: 1px;
      }
    }

    &:hover,
    &:focus {
      background-color: $category-hover-background;
      color: $andes-white;
      max-width: 100%;
      z-index: 1;
      padding-right: 11px;

      .nc-icon {
        color: $andes-white;
      }

      @media (width <= 1196px) {
        p {
          padding-right: 1px;
        }
      }
    }
  }

  .categories-cvs-icons {
    display: none;
  }

  &-loading {
    height: 2 * $category-size;
    width: calc(100vw - 100px);
  }
}
