@import 'app/styles/variables';

.#{$menu-prefix}-top {
  $box-shadow-color: rgb(0 0 0 / 12%);
  $children-category-initial-padding-left: 16px;

  &-content {
    background-color: $andes-white;
    box-shadow: 0 1px 2px $box-shadow-color;
    box-sizing: border-box;
    min-height: 60px;
    max-height: 80px;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    z-index: 1;

    &-menu {
      background: $andes-white;
      box-shadow: 0 6px 16px $andes-gray-100;
      border-radius: 0 0 6px 6px;
      color: $andes-black;
      display: block;
      margin: 2.93px 0 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 100%;
      width: 100%;
      z-index: 4;

      &.not-display {
        display: none;
      }

      &-li-active {
        border-left: 2px solid $andes-blue-500;
      }

      &-anchor-active {
        color: $andes-blue-500;
        margin-left: -2px;
      }

      &-anchor-not-active {
        color: $andes-gray-900;
      }

      ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 14px;
        font-weight: 400;

        + .children-categories {
          max-height: 350px;
          overflow: scroll;
        }

        li a {
          font-family: $font-family;
          font-size: 16px;
          line-height: 20px;
          display: block;
          height: 34px;
          padding-top: 14px;
          text-decoration: none;
        }
      }

      &-level-0 {
        padding-left: $children-category-initial-padding-left;
      }

      &-level-1 {
        padding-left: $children-category-initial-padding-left * 2;
      }

      &-level-2 {
        padding-left: $children-category-initial-padding-left * 3;
      }

      &-level-3 {
        padding-left: $children-category-initial-padding-left * 4;
      }

      &-level-4 {
        padding-left: $children-category-initial-padding-left * 5;
      }

      &-level-5 {
        padding-left: $children-category-initial-padding-left * 6;
      }

      &-level-6 {
        padding-left: $children-category-initial-padding-left * 7;
      }

      &-level-7 {
        padding-left: $children-category-initial-padding-left * 8;
      }
    }
  }

  &-toggle-button {
    text-align: left;
    padding: 16px 23px 14px;

    &-title {
      display: block;
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
      padding-bottom: 5px;
    }

    &-category-name {
      font-family: $font-family;
      font-size: 16px;
      line-height: 16px;
      color: $andes-gray-900;
      margin-right: 2px;
    }
  }
}
