@import 'app/styles/variables';

.#{$search-prefix}-container-expandable {
  $box-shadow-color: rgb(0 0 0 / 12%);

  .section-header.andes-card__header {
    padding: 20px;
  }

  .container {
    margin-left: 8.65px;
    margin-right: 8.65px;
  }

  .wrapper {
    overflow: hidden;

    @media (width >= 375px) {
      .search-entry-wrapper {
        margin: 0 0 1px;
      }
    }
  }

  .section-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $andes-white;
    border-radius: 4px;
    color: $andes-blue-500;
    font-family: $font-family;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-decoration: none;
    border: none;
    height: 60px;

    svg {
      margin-left: 5px;

      path {
        fill: #3483fa;
      }
    }
  }

  .search-entry-wrapper {
    display: table;
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    &-cell {
      display: table-cell;
      vertical-align: top;
      height: 100%;
      width: 50%;

      .ui-search-entry-images-container {
        width: 145px;
      }

      .ui-search-entry-keyword {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .andes-card {
      border-radius: 0;
    }

    .not-display {
      display: none;
    }
  }

  @media (width <= 374px) {
    .search-entry-wrapper {
      display: block;

      &-cell {
        display: block;
        width: 100%;
        margin-bottom: 1px;
      }
    }
  }
}
