@import 'app/styles/variables';
@import '~@andes/common/index';

$category-hover-background: $andes-blue-500;

.#{$category-prefix}-navigation {
  &-category {
    display: block;
    background-color: $andes-white;
    box-sizing: border-box;
    color: $andes-black;
    font-family: $font-family;
    font-size: 14px;
    line-height: 18px;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  &-icon {
    width: 44px;
    height: 44px;
    margin: 32px auto 14px;
  }

  &-nc-icon {
    height: 48px;
    width: 48px;
    fill: none;
    stroke: currentcolor;
    color: $category-hover-background;

    use {
      fill: inherit;
      stroke: inherit;
      stroke-width: 2px;
    }
  }
}
