//Todo sacar eslint cuando se implemente
//sass-lint:disable-block  no-empty-rulesets

@import 'app/styles/variables';

.#{$search-prefix}-container {
  display: flex;
  justify-content: center;

  &-searches {
    display: flex;
    flex-wrap: wrap;
  }

  &-desktop-without-menu {
    width: calc(100vw - 100px);
    max-width: 1200px;
  }

  &-desktop-with-menu {
    width: calc(100vw - 415px);
    max-width: 961px;
  }

  &-desktop-without-menu,
  &-desktop-with-menu {
    .consolidate {
      gap: 8px;
    }

    .featured {
      gap: 20px 8px;
    }

    .ui-search-entry-container {
      height: 146px;

      &:hover {
        z-index: 1;
      }
    }
  }

  &-mobile {
    width: 100%;

    .ui-search-entry-container {
      flex-grow: 1;
      flex-basis: 45%;
      height: auto;
    }

    .ui-search-entry-images-container {
      width: 145px;
    }

    .andes-card {
      border-radius: 0;
    }

    .ui-search-entry-keyword {
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: fit-content;
    }

    .consolidate {
      gap: 1px;
    }

    .featured {
      gap: 1px 11px;
    }
  }
}
