@import 'app/styles/variables';

.#{$view-prefix}-desktop {
  &-div-with-category {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-without-menu {
    margin-top: 48px;
  }

  &-with-menu {
    margin-top: 24px;
  }
}
