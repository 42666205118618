$andes-theme: 'mercadolibre';

// Prefixes
$category-prefix: 'ui-category';
$search-prefix: 'ui-search';
$menu-prefix: 'ui-menu';
$view-prefix: 'ui-view';
$error-prefix: 'ui-error';

// Fonts
$font-family: 'Proxima Nova';
